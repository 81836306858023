import useRole from '../../store/user/hooks/useRole';

import {
  CLINIC_MANAGEMENT,
  RELEASE_MANAGEMENT,
  ACT_TEAM_MANAGEMENT,
  DICTIONARIES,
  MY_CLINIC,
  CLIENT,
  MY_ACT_TEAM,
  MY_CLIENTS,
  SUPER_ADMIN,
  MEDICATION_LOGISTICS,
  COORDINATE,
  VISITS,
  REPORTS,
  ORGANIZATION_MANAGEMENT,
  RESOURCE,
  SYSTEM_ALERTS_MANAGEMENT,
  MY_CLIENT_ALLOCATIONS,
} from './paths';

import { ReactComponent as HomeIcon } from '~/ui/assets/images/home.svg';
import { ReactComponent as ActTeamIcon } from '~/ui/assets/images/actTeam.svg';
import { ReactComponent as ClientsIcon } from '~/ui/assets/images/clients.svg';
import { ReactComponent as ReportsIcon } from '~/ui/assets/images/reports.svg';
import { ReactComponent as DictionariesIcon } from '~/ui/assets/images/dictionaries.svg';
import { ReactComponent as OrganizationsIcon } from '~/ui/assets/images/organizations.svg';
import { ReactComponent as PersonIcon } from '~/ui/assets/images/persons.svg';
import { ReactComponent as PillIcon } from '~/ui/assets/images/pill.svg';
import { ReactComponent as CoordinateIcon } from '~/ui/assets/images/coordinate.svg';
import { ReactComponent as VisitsIcon } from '~/ui/assets/images/visits.svg';
import { ReactComponent as ResourcesIcon } from '~/ui/assets/images/resources.svg';
import { ReactComponent as ReleaseRedIcon } from '~/ui/assets/images/release.svg';
import { ReactComponent as DangerIcon } from '~/ui/assets/images/danger.svg';

import variables from '~/ui/assets/styles/colors.module.scss';

interface IMenuItem {
  url: string;
  title: string;
  icon: ({ isActive }: { isActive: boolean }) => JSX.Element;
}

const sidebarMenuItems = (): IMenuItem[] => {
  const {
    isSuperAdmin,
    isProductOwner,
    isLocalAdmin,
    isActTeam,
    isProgramAssistant,
    isGlobalUser,
  } = useRole();

  const commonPermissions = isLocalAdmin || isProgramAssistant || isActTeam || isGlobalUser;

  const permissions = {
    clinicManagement: isSuperAdmin || isProductOwner,
    myClinic: commonPermissions,
    dictionaries: isSuperAdmin || isProductOwner,
    clients: isLocalAdmin || isProgramAssistant,
    reports: commonPermissions,
    superAdmins: isProductOwner,
    myClientAllocations: isGlobalUser,
    myClients: isGlobalUser,
    myActTeam: isActTeam,
    coordinate: isActTeam || isGlobalUser,
    medicationLogistics: commonPermissions,
    visits: commonPermissions,
    resources: isSuperAdmin || commonPermissions,
    systemAlerts: isSuperAdmin,
  };

  return [
    ...(permissions.reports
      ? [
          {
            url: REPORTS,
            title: 'Reports',
            // eslint-disable-next-line react/display-name

            icon: ({ isActive }: { isActive: boolean }) => (
              <ReportsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.myClinic
      ? [
          {
            url: MY_CLINIC,
            title: 'My Clinic',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <HomeIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),

    ...(permissions.clinicManagement
      ? [
          {
            url: CLINIC_MANAGEMENT,
            title: 'Clinic management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <HomeIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),

    ...(permissions.dictionaries
      ? [
          {
            url: DICTIONARIES,
            title: 'Dictionaries',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <DictionariesIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),

    ...(permissions.clients
      ? [
          {
            url: ACT_TEAM_MANAGEMENT,
            title: 'Team Management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ActTeamIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
          {
            url: CLIENT,
            title: 'Clients',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ClientsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.myActTeam
      ? [
          {
            url: MY_ACT_TEAM,
            title: 'Team Management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ActTeamIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
          {
            url: MY_CLIENTS,
            title: 'Clients',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ClientsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.myClients
      ? [
          {
            url: MY_CLIENT_ALLOCATIONS,
            title: 'Client Allocation',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ActTeamIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
          {
            url: MY_CLIENTS,
            title: 'Clients',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ClientsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.superAdmins
      ? [
          {
            url: SUPER_ADMIN,
            title: 'Super Admins',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <PersonIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
          {
            url: ORGANIZATION_MANAGEMENT,
            title: 'Organizations',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <OrganizationsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.medicationLogistics
      ? [
          {
            url: MEDICATION_LOGISTICS,
            title: 'Medication Logistics',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <PillIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.coordinate
      ? [
          {
            url: COORDINATE,
            title: 'Coordinate',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <CoordinateIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.visits
      ? [
          {
            url: VISITS,
            title: 'Visits',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <VisitsIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.resources
      ? [
          {
            url: RESOURCE,
            title: 'Resources',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ResourcesIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
        ]
      : []),
    ...(permissions.systemAlerts
      ? [
          {
            url: RELEASE_MANAGEMENT,
            title: 'Release management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <ReleaseRedIcon color={isActive ? variables.colorRed : variables.colorGrey} />
            ),
          },
          {
            url: SYSTEM_ALERTS_MANAGEMENT,
            title: 'System Alerts',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <DangerIcon
                color={isActive ? variables.colorRed : variables.colorGrey}
                width={22}
                height={22}
              />
            ),
          },
        ]
      : []),
  ];
};

export default sidebarMenuItems;
