import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamMemberActions, IThunkUnlockGlobalUser } from '../types';

const onUnlockGlobalUser: IThunkUnlockGlobalUser = thunk(
  async ({ activateTeamMember }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, teamMemberId } = payload;

    await api.actTeamMember.unlockGlobalUser(clinicId, teamMemberId);

    activateTeamMember(teamMemberId);
  },
);

export default onUnlockGlobalUser;
