import { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import unionBy from 'lodash/unionBy';

import useRole from '~/store/user/hooks/useRole';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import UserProfile from '~/ui/components/common/UserProfile';
import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';

import {
  ACT_TEAM_MANAGEMENT,
  EDIT_TEAM_MEMBER,
  MY_ACT_TEAM,
  VIEW_ACT_TEAM,
} from '~/ui/constants/paths';
import { IClinic } from '~/services/api/clinic/types';
import { IActTeamMember } from '~/services/api/actTeamMember/types';

import EditIcon from '~/ui/assets/images/editWhiteSecond.svg';
import styles from './TeamMemberProfile.module.scss';

type ILocationState = { pathname: string; state: { actTeamId: string; selectedTab: number } };

const TeamMemberProfile = (): ReactElement => {
  const navigate = useNavigate();
  const { actTeamId, teamMemberId } = useParams<{ actTeamId: string; teamMemberId: string }>();
  const location: ILocationState = useLocation();

  const [loading, setLoading] = useState(false);

  const actTeamMember = useStoreState(state => state.actTeamMember.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const { isArchived } = actTeam || {};

  const { isActTeam, isGlobalUser } = useRole();

  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));
  const { clinic: userClinic } = useStoreState(state => state.user.current);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamMember = useStoreActions(actions => actions.actTeamMember.onGetActTeamMember);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([
        onGetMyClinic(),
        onGetActTeamMember({ teamMemberId, actTeamId, clinicId: String(userClinic.id) }),
        onGetActTeam({ clinicId: String(userClinic.id), actTeamId }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    onGetActTeam,
    onGetActTeamMember,
    onGetMyClinic,
    showError,
    teamMemberId,
    userClinic.id,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  // show assigned teams only from current clinic
  const filteredTeams = unionBy(
    (actTeamMember as IActTeamMember)?.roles
      .filter(role => role.clinic.id === userClinic?.id)
      .flatMap(role => role.teams),
    'id',
  );

  const user = useMemo(
    () => ({ ...actTeamMember, teams: filteredTeams }),
    [actTeamMember, filteredTeams],
  );

  if (loading || !actTeam || !actTeamMember || !clinic) return <Loader />;

  const breadcrumbsItems = [
    ...(!isActTeam
      ? [
          {
            title: `${userClinic.name} Teams`,
            to: ACT_TEAM_MANAGEMENT,
          },
          {
            title: actTeam.name,
            to: {
              pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId),
              state: { selectedTab: 1 },
            },
          },
        ]
      : [
          {
            title: actTeam.name,
            to: {
              pathname: MY_ACT_TEAM,
              state: { selectedTab: isActTeam ? 2 : 1 },
            },
          },
        ]),
    {
      title: 'Team Member Profile',
      to: location.pathname,
    },
  ];

  const pushToEditTeamMember = () =>
    navigate(
      EDIT_TEAM_MEMBER.replace(':actTeamId', actTeamId).replace(
        ':teamMemberId',
        String(actTeamMember.id),
      ),
    );

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>
            {actTeamMember.firstName} {actTeamMember.lastName}
          </h2>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
        </div>
        <div className={styles.headerRightSide}>
          <div
            className={classNames({ [styles.invisible]: isActTeam || isGlobalUser || isArchived })}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<img src={EditIcon} alt="edit" />}
              onClick={pushToEditTeamMember}
            >
              Edit Member&apos;s Profile
            </Button>
          </div>
        </div>
      </div>
      <UserProfile user={delete user.roles && user} personalDetails address workDetails />
    </div>
  );
};

export default TeamMemberProfile;
