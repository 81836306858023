import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import unionBy from 'lodash/unionBy';

import Loader from '~/ui/components/common/Loader';
import PersonalDetails from '~/ui/pages/ActTeam/reusable/PersonalDetails';
import Header from './Header';
import EmptyState from '~/ui/pages/Notifications/components/EmptyState';
import WorkDetails from './WorkDetails';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

type IParams = {
  id: string;
  programAssistantId: string;
};

const ViewProgramAssistant = (): ReactElement => {
  const { id: clinicId, programAssistantId } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(true);

  const currentClinic = useStoreState(states => states.clinic.current);
  const currentProgramAssistant = useStoreState(states => states.programAssistant.current);

  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetCurrentProgramAssistant = useStoreActions(
    actions => actions.programAssistant.onGetCurrentProgramAssistant,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = useCallback(async () => {
    setLoading(true);
    try {
      await onGetClinic(clinicId);
      await onGetCurrentProgramAssistant({ clinicId, programAssistantId: +programAssistantId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, onGetClinic, onGetCurrentProgramAssistant, programAssistantId, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  // show assigned teams only from current clinic
  const filteredTeams = unionBy(
    currentProgramAssistant?.roles
      .filter(role => role.clinic.id === currentClinic?.id)
      .flatMap(role => role.teams),
    'id',
  );

  const user = useMemo(
    () => ({ ...currentProgramAssistant, teams: filteredTeams }),
    [currentProgramAssistant, filteredTeams],
  );

  if (loading) return <Loader />;

  if (!loading && !currentProgramAssistant) return <EmptyState />;

  return (
    <>
      <Header
        clinicId={clinicId}
        clinicName={currentClinic?.name || ''}
        programAssistantId={programAssistantId}
        programAssistantName={`${currentProgramAssistant?.firstName || ''} ${
          currentProgramAssistant?.lastName || ''
        }`}
      />
      <Grid container sm={12}>
        {currentProgramAssistant && <PersonalDetails teamMember={currentProgramAssistant} />}
      </Grid>
      <Grid container sm={12}>
        {user && currentClinic && <WorkDetails teamMember={user} clinicName={currentClinic.name} />}
      </Grid>
    </>
  );
};

export default ViewProgramAssistant;
